import React from 'react'

//Helpers
import {getLocation, getName, getFakeAddress, getStatus, is_Sold} from '../helpers/helper.developments'
import {getCover, getQuality} from '../helpers/helper.rendering'
import { graphql, useStaticQuery } from 'gatsby';
import { getEnvironment, getPrices, getSurface, makeLink } from '../helpers/helper.properties';
import { changeSelectedAction } from '../redux/propertiesDucks';
import { connect } from 'react-redux';

const developmentCard = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)
    const {development,skeleton,dispatch} = props
    const inMap = props?.inMap
    const unities = props?.unities

    const makeUrlUnities = (unities) => {
        let url = ''
        unities.forEach((unity, index) => {
            url += `${unity.id}`
            if(index !== unities.length - 1){
                url += `-`
            }
        })
        return url
    }

    return inMap ? (
            <div class={"card prop-card card-dev " + (inMap ? 'map-card' : '')}>
            <div class={"card-image grid_map_image"}>
                <a target="_blank" >
                    <img src={getQuality(getCover(development.photos)) } className='notScale' alt={realEstate?.name} />
                </a>
                <div data-bs-dismiss="modal" onClick={() => dispatch(changeSelectedAction(0))} className="close-card"><i></i><i></i></div>
                <div  class="badges">
                    <div  className={"badge"}>{"EMPRENDIMIENTO"}</div>
                </div>
            </div>
            <div class="card-body">
                <div class="row d-flex justify-content-between align-items-start">
                    <div className='col-12 mb-2'>
                        <h3 class="card-title">
                            <a target={"__blank"} >{getName(development)}</a>
                        </h3>   
                    </div>
                </div>
                <div class="content-extra row d-flex justify-content-between align-items-start">
                    <div class="col-12 mb-3 extra-data">
                        <span>{getFakeAddress(development)}, <span>
                            {getLocation(development)}
                        </span></span>
                    </div>
                    {/* <div class="col-12 col-lg-6 mb-2 text-lg-end location">
                        <span>
                            {getLocation(development)}
                        </span>
                    </div> */}
                </div>
                <div class="content-extra">
                    <div className="unity">
                            {unities.length < 7 ? 
                                unities.map((unity, index) => (
                                    <div className="item-unity mb-1 d-flex align-items-center" key={index}>
                                        {getEnvironment(unity)}ambientes · {getSurface(unity) || '000.00 M2'} · {getPrices(unity).currency} USD 650.000 <a className="ms-2" target="__blank" href={makeLink(unity)}>VER </a>
                                    </div>
                                ))
                                :''
                            }
                    </div>
                </div>
                {unities.length > 6 ? 
                    <a target={"__blank"} href={"/emprendimientos/" + development?.id} class="btn btn-orange w-100">Ver Emprendimiento</a>
                : 
                ""}
            </div>
        </div>
        )
        :(
        <div class={"card dev-card " + (skeleton && " skeleton ") + (props.inMap ? 'map-card' : '')}>
            {is_Sold(development) && 
                <div className="development-end-tag">100% VENDIDO</div>
            }
            <div class="over-image-badge">{development && getStatus(development)}</div>
            <div class="card-image">
                <img class="card-img" src={development && getQuality(getCover(development.photos))} alt={realEstate?.name} />
            </div>
            <div class="card-img-overlay d-flex align-items-end align-items-lg-start flex-lg-column text-white">
                <div class="content">
                    {/* <div class="badge">Estado <span>----</span> {development && getStatus(development)}</div> */}
                    <h4 class="card-title mb-0 mb-lg-3 mt-lg-5">{development && getName(development)}</h4>   
                    <h4 class="d-inline-block card-title direccion mb-0 mb-lg-3 mt-lg-2">{development && getFakeAddress(development)}</h4>   
                    <h5 class="card-location">
                        {development && getLocation(development,true)[0]}
                        <br />
                        {development &&  getLocation(development,true)[1]}
                    </h5>
                </div>
                <a target={"__blank"} href={"/emprendimientos/" + development?.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')} class="btn btn-outline mt-auto"><span class="d-none d-lg-inline">DESCUBRILO</span><span class="icon-plus d-lg-none"></span></a>
            </div>
        </div> 
    )
}

export default connect(state => ({
    settings: state.settings,
}),null)(developmentCard);